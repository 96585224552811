<template>
  <!-- TODO : Add a wysiwyg component -->
  <UiWysiwyg class="p-4 lg:p-6 bg-grisBackground font-raleway text-regular" :content="paragraphData?.fieldBody" />
</template>
<script>
export default {
  name: "ParagraphGreyBackground",
  props: {
    paragraphData: {
      type: Object,
      required: true,
    },
  },
};
</script>
