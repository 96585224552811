<template>
  <div>
    <div v-if="paragraphData.fieldSubtitle" class="text-lg font-medium text-orangeCurie font-lora my-0 text-center">
      {{ paragraphData.fieldSubtitle }}
    </div>

    <h2 v-if="paragraphData.fieldTitle"
        class="font-normal font-raleway leading-[35px] lg:leading-[52px] text-h2Small lg:text-h2 mt-2.5 mb-6 text-center">
      {{ paragraphData.fieldTitle }}
    </h2>

    <div v-if="paragraphData.fieldIntroduction"
         class="font-raleway text-regularMobile leading-[25px] lg:text-regular text-center mb-6 lg:mb-10">
      {{ paragraphData.fieldIntroduction }}
    </div>

    <div class="flex flex-wrap gap-4"
         :class="{'justify-center': attrs.sectionType == 'full',}"
    >
      <div
          v-for="item in paragraphData.fieldKeyFigureItem"
          class="flex items-center p-4 border border-grisClair rounded w-full lg:w-auto"
      >
        <div
            class="mr-4 whitespace-nowrap font-lora text-orangeCurie font-normal text-[2.5rem] lg:text-[3.125rem]"
        >
          {{ item.fieldNumber }}
        </div>
        <p class="text-[17px] lg:text-[14px] font-normal font-raleway lg:max-w-[180px]">
          {{ item.fieldText }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

const props = defineProps({
  paragraphData: Object,
});

const attrs = useAttrs()
</script>
