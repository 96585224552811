<template>
  <div v-if="paragraphData">
    <h2
      v-if="paragraphData.fieldTitle"
      class="font-normal font-raleway text-[2rem] lg:text-h2 mb-6"
    >
      {{ paragraphData.fieldTitle }}
    </h2>
    <div v-if="paragraphData.fieldBody || paragraphData.fieldLink" class="flex flex-wrap items-end justify-between w-full">
      <div
        v-if="paragraphData.fieldBody"
        v-html="paragraphData.fieldBody"
        class="wysiwyg max-w-[830px] text-lg font-normal font-raleway"
      />
      <div class="self-end md:flex">
        <UiCustomLink
          v-if="paragraphData.fieldLink" 
          :path="paragraphData.fieldLink.uri.path"
          class="flex text-lg font-bold underline font-raleway hover:text-orangeCurie"
        >
          {{ paragraphData.fieldLink.title }}
          <nuxt-icon name="arrowRight" filled class="ml-2 pt-1" />
        </UiCustomLink>
      </div>
      
    </div>
    <component :is="componentInstance" :links="paragraphData.fieldLinkItem" :column="paragraphData.fieldColumnNumber"/>
    <!-- <ul :class="getColumnsNumber" class="grid grid-cols-1 gap-4 mt-6">
      <li
        v-for="item in paragraphData.fieldLinkItem"
        :key="item.id"
        class="px-4 border border-gray-200 rounded-md cursor-pointer"
        :class="getClass"
      >
        <div class="flex items-center text-lg font-bold font-raleway">
          <UiCustomImage
            v-if="item.fieldImage"
            :src="item.fieldImage.entity.uriRawField.list[0].url"
            :alt="item.fieldImage.alt"
            presets="paragraph_link"
            class="mr-4"
          />
          {{ item.fieldLink.title }}
        </div>
        <nuxt-icon name="arrowRight" filled />
      </li>
    </ul> -->
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  paragraphData: {
    type: Object,
    required: true,
  },
});

const componentInstance =
  parseInt(props.paragraphData.fieldColumnNumber) > 1
    ? resolveComponent("CommonParagraphLinkMultipleColumns")
    : resolveComponent("CommonParagraphLinkOneColumn");
</script>
