<template>
  <div class="pl-4 lg:pl-14 border-l-2 border-orangeCurie">
    <div class="font-lora font-normal text-h3Small lg:text-medium leading-[31px] lg:leading-[30px]">
      {{ paragraphData.fieldQuotation }}
    </div>
    <p class="my-4 text-sm font-normal font-raleway">
      <span class="font-bold">{{ paragraphData.fieldAuthor }}</span>
      {{ paragraphData.fieldJob }}
    </p>
  </div>
</template>
<script>
export default {
  name: "ParagraphQuotation",
  props: {
    paragraphData: {
      type: Object,
      required: true,
    },
  },
};
</script>
