<template>
  <component :is="`${paragraphData?.fieldTitleLevel}`" :class="headerClass()">
    {{ paragraphData?.fieldTitle }}
  </component>
</template>

<script setup lang="ts">
import type { ParagraphTitle } from "~/interfaces/paragraph";

const props = defineProps({
  paragraphData: Object as PropType<ParagraphTitle>,
});

const classMap = {
  h1: "font-normal font-lora text-h3 lg:text-h1 leading-[45px] lg:leading-[57px]",
  h2: "font-normal font-lora leading-[35px] lg:leading-[52px] text-h2Small lg:text-h2",
  h3: "font-lora text-medium lg:text-h3Small font-bold",
  h4: "font-bold font-raleway text-medium",
};

const headerClass = () =>
  classMap[props.paragraphData.fieldTitleLevel] || "font-normal font-lora";
</script>
