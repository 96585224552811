<template>
  <div class="my-10" :class="getGridClasses">
    <div
      v-for="(pushItem, index) in paragraphData.fieldPushItem"
      :key="index"
      class="border border-grisClair rounded"
      :class="getItemsClasses"
    >
      <CustomImage
        v-if="pushItem.fieldImage"
        :src="pushItem.fieldImage.entity.uriRawField.list[0].url"
        :alt="pushItem.fieldImage.alt"
        :presets="getPresetImage"
        class="w-full rounded object-cover"
        :class="getOneItemsImgSpaceClasses"
      />
      <div class="grow">
        <p v-if="pushItem.fieldSubtitle" class="text-lg font-medium text-orangeCurie font-lora">
          {{ pushItem.fieldSubtitle }}
        </p>
        <h2
          v-if="pushItem.fieldTitle"
          class="text-h3Medium leading-[33px] lg:leading-[40px] lg:text-h2Small m-0 mt-2.5 mb-4 lg:mb-2 font-normal font-lora text-grisFont"
        >
          {{ pushItem.fieldTitle }}
        </h2>
        <div v-html="pushItem.fieldBody" class="wysiwyg font-raleway" :class="getOneItemsWidth" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomImage from "~/components/ui/CustomImage.vue";
import Button from "~/components/ui/Button.vue";
export default {
  name: "ParagraphPush",
  components: {
    Button,
    CustomImage,
  },
  props: {
    paragraphData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getGridClasses() {
      const numberOfItems = this.paragraphData.fieldPushItem.length;
      let columns;
      switch (numberOfItems) {
        case 1:
          columns = "";
          break;
        case 2:
          columns = "grid-cols-1 lg:grid-cols-2";
          break;
        case 3:
          columns = "grid-cols-1 lg:grid-cols-3";
          break;
        default:
          columns = "grid-cols-1 lg:grid-cols-3";
          break;
      }
      return `grid ${columns} gap-4`;
    },
    getPresetImage() {
      const numberOfItems = this.paragraphData.fieldPushItem.length;
      let preset;
      switch (numberOfItems) {
        case 1:
          preset = "paragraph_columns";
          break;
        case 2:
          preset = "paragraph_push_2";
          break;
        case 3:
          preset = "paragraph_push_3";
          break;
        default:
          preset = "paragraph_columns";
          break;
      }
      return preset;
    },
    getItemsClasses() {
      return this.paragraphData.fieldPushItem.length === 1 ? "flex flex-col lg:flex-row p-4 lg:p-6" : "flex-col p-4 lg:p-10";
    },
    getOneItemsImgSpaceClasses() {
      return this.paragraphData.fieldPushItem.length === 1 ? "lg:mr-16 mb-6 lg:mb-0" : "mb-6 lg:mb-4";
    },
    getOneItemsWidth() {
      if(this.paragraphData.fieldPushItem[0].fieldImage){
        return this.paragraphData.fieldPushItem.length === 1 ? "max-w-[80%]" : "";
      }

      return '';

    },
  },
};
</script>
