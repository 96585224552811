<template>
  <div
      :class="`grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-16 w-full`">
    <div class="col-span-1" :class="`${
      paragraphData.fieldPositionImage === 'right'
        ? 'order-2'
        : 'order-1'
    }`">
      <CustomImage
          v-if="paragraphData.fieldImage"
          :src="paragraphData.fieldImage.entity.uriRawField.list[0].url"
          :alt="paragraphData.fieldImage.alt"
          presets="paragraph_text_image"
          class="flex-1 w-full rounded object-cover"/>
    </div>
    <div class="col-span-1 lg:col-span-1" :class="`${
      paragraphData.fieldPositionImage === 'right'
        ? 'order-1'
        : 'order-2'
    }`">
      <p class="mb-2.5 text-lg font-medium lg:mb-4 text-orangeCurie font-lora lg:leading-[15px]">
        {{ paragraphData.fieldSubtitle }}
      </p>
      <h2
          class="font-raleway text-[2rem] lg:text-h2 leading-[35px] lg:leading-[52px] font-normal text-grisFont mb-10 lg:mb-6 mt-0"
      >
        {{ paragraphData.fieldTitle }}
      </h2>
      <div
          v-html="paragraphData.fieldBody"
          class="text-lg font-normal text-grisFont font-raleway wysiwyg"
      />
    </div>
  </div>
</template>

<script>
import CustomImage from "~/components/ui/CustomImage.vue";

export default {
  name: "ParagraphTextImage",
  components: {
    CustomImage,
  },
  props: {
    paragraphData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getFlexDirectionClasses() {
      return this.paragraphData.fieldPositionImage === "right"
          ? "flex flex-row-reverse"
          : "flex flex-row";
    },
  },
};
</script>
