<template>
  <div class="px-4 py-8 rounded border border-grisClair xl:p-16 xl:pb-8">
    <div
      class="flex flex-col items-start pb-4 border-b border-grisClair xl:flex-row xl:pb-2 lg:items-center"
    >
      <NuxtImg
        src="/image/donation.svg"
        class="w-[110px]"
        alt="Image Donation"
      />
      <div
        class="font-normal leading-snug lg:ml-6 font-raleway text-[32px] xl:text-h2"
      >
        {{ paragraphData.fieldTitle }}
      </div>
    </div>
    <div
      class="flex flex-col justify-between py-8 pb-0 xl:flex-row order-gray-200"
    >
      <div class="flex flex-col xl:basis-auto">
        <ul class="grid grid-cols-6 gap-y-3 gap-x-3 lg:gap-3">
          <li
            v-for="(donation, index) in donationPrice"
            class="flex items-center justify-center p-4 hover:outline hover:outline-darkOrange hover:outline-2 hover:text-darkOrange outline-grisClair rounded outline-1 font-lora font-normal text-[28px] cursor-pointer text-grisFont text-center"
            :key="index"
            @click="selectDonation(donation.price)"
            :class="[
              'p-4 rounded-md font-lora font-normal text-[25px] cursor-pointer text-grisFont',
              index > 3
                ? 'col-span-4 xl:col-span-2'
                : 'col-span-2 xl:col-span-1',
              {
                'bg-white outline outline-orangeCurie !outline-2 text-orangeCurie':
                  selectedPrice === donation.price,
                outline: selectedPrice !== donation.price,
              },
            ]"
          >
            {{ donation.text }}
          </li>
        </ul>
        <p
          v-if="selectedPrice !== 0"
          class="flex mt-6 mb-6 font-normal text-gray-400 xl:items-center text-medium lg:text-regular font-lora"
        >
          <nuxt-icon
            name="info"
            filled
            class="pt-1 mr-2.5 xl:pt-0 text-[24px]"
          />
          {{ $t("paragraphDonation.fiscalityDonnation") }}
          {{ ((selectedPrice / 100) * (1 - 0.66)).toFixed(2) }}€
        </p>
      </div>
      <div class="flex flex-col items-center">
        <UiCustomLink
          :class="selectedPrice === 0 ? 'mt-6 lg:mt-0' : ''"
          :path="`https://aider.curie.fr?amount=${selectedPrice}#once`"
          class="px-10 py-6 mb-4 w-full font-medium text-center text-white rounded bg-orangeCurie font-lora text-h3Medium hover:bg-orangeHover"
          target="_blank"
          @click="triggerEventOnce"
        >
          {{ $t("paragraphDonation.oneTimeDonnation") }}
        </UiCustomLink>
        <UiCustomLink
          path="https://aider.curie.fr#regular"
          class="mt-2 underline font-lora text-h3Medium xl:text-regularBig text-orangeCurie hover:text-darkOrange"
          target="_blank"
          @click="triggerEventMonth"
        >
          {{ $t("paragraphDonation.monthlyDonnation") }}
        </UiCustomLink>
      </div>
    </div>
  </div>
</template>
<script setup>
const gtm = useGtm();

const props = defineProps({
  paragraphData: {
    type: Object,
    required: true,
  },
});

const donationPrice = [
  {
    price: 5000,
    text: "50€",
  },
  {
    price: 10000,
    text: "100€",
  },
  {
    price: 15000,
    text: "150€",
  },
  {
    price: 50000,
    text: "500€",
  },
  {
    price: 0,
    text: "Montant libre",
  },
];

const selectedPrice = ref(15000);

const selectDonation = (price) => {
  selectedPrice.value = price;
};

function triggerEventOnce() {
  gtm.trackEvent({
    event: 'bloc_don_valeur',
    donation_amount: (selectedPrice.value / 100),
    donation_type: "Une fois",
  })
}

function triggerEventMonth() {
  gtm.trackEvent({
    event: 'bloc_don_valeur',
    'donation_amount': (selectedPrice.value / 100),
    'donation_type': "Chaque mois",
  })
}
</script>
