<template>
  <div class="grid grid-cols-6 lg:gap-12 p-2 my-12 lg:grid-cols-12">
    <div :class="getClassBySectionType('image')">
      <CustomImage
          :src="paragraphData.fieldImage.entity.uriRawField.list[0].url"
          :alt="paragraphData.fieldImage.alt"
          presets="paragraph_testimony"
          customClass="w-full lg:w-[260px] max-h-[270px] lg:ml-auto rounded-[50%]"
      />
    </div>
    <div class="flex flex-col md:items-start ml-4 lg:ml-0" :class="getClassBySectionType('content')">
      <div class="text-lg font-medium text-orangeCurie font-lora my-0">
        {{ paragraphData.fieldSubtitle }}
      </div>
      <div
          class="font-lora font-normal text-medium text-left lg:text-[34px] lg:leading-10 text-grisFont my-4"
      >
        {{ paragraphData.fieldText }}
      </div>
      <p class="lg:mb-10 text-[15px] lg:text-sm font-bold font-raleway">
        {{ paragraphData.fieldFirstName }},
        <span class="font-normal">
          {{ paragraphData.fieldAge }}
        </span>
      </p>
      <div class="hidden lg:block">
        <Button
            :buttonLabel="paragraphData.fieldLink.title"
            :url="paragraphData.fieldLink.uri.path"
            size="large"
            :withIcon="false"
            variant="primary"
            class="text-center"
        />
      </div>
    </div>
    <div class="mt-8 col-span-12 block lg:hidden text-center">
      <Button
          :buttonLabel="paragraphData.fieldLink.title"
          :url="paragraphData.fieldLink.uri.path"
          size="large"
          :withIcon="false"
          variant="primary"
          class="text-center w-full block"
      />
    </div>
  </div>
</template>
<script>
import CustomImage from "../ui/CustomImage.vue";
import Button from "../ui/Button.vue";

export default {
  name: "ParagraphTestimony",
  components: {
    CustomImage,
    Button,
  },
  props: {
    paragraphData: {
      type: Object,
      required: true,
    },
    sectionType: {
      type: String,
      required: true,
    }
  },
  methods: {
    getClassBySectionType(typeOfBlock) {
      if (this.sectionType === 'border') {
        if(typeOfBlock === 'image'){
          return 'col-span-2 lg:col-span-4';
        }
        if(typeOfBlock === 'content'){
          return 'col-span-4 lg:col-span-8';
        }
      }else if(this.sectionType === 'full'){
        if(typeOfBlock === 'image'){
          return 'col-span-2 lg:col-span-4';
        }
        if(typeOfBlock === 'content'){
          return 'col-span-4 lg:col-span-8';
        }
      }else {
        if(typeOfBlock === 'image'){
          return 'col-span-2 lg:col-start-2 lg:col-span-3';
        }
        if(typeOfBlock === 'content'){
          return 'col-span-4 lg:col-span-8';
        }
      }
    },
  },
};
</script>
