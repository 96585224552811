<template>
  <div>
    <div v-if="paragraphData.fieldSubtitle" class="text-lg font-medium font-lora text-orangeCurie text-center lg:text-left">
      {{ paragraphData.fieldSubtitle }}
    </div>
    <h2 v-if="paragraphData.fieldTitle" class="font-normal text-center lg:text-left font-raleway text-[2rem] lg:text-h2">
      {{ paragraphData.fieldTitle }}
    </h2>
    <div v-if="paragraphData.fieldBody"
        v-html="paragraphData.fieldBody"
        class="wysiwyg text-lg text-center lg:text-left font-normal font-raleway"
    />
    <div class="grid gap-4 lg:gap-6 mt-6 grid-cols-12">
      <div class="col-span-4 lg:col-span-2 rounded-md" v-for="(logo, index) in paragraphData.fieldImages" :key="index">
        <div class="border border-gray-200 lg:mb-2">
          <UiCustomLink :path="logo.title ? logo.title : ''">
            <CustomImage
                :src="logo.entity.uriRawField.list[0].url"
                presets="paragraph_thumbnail"
                class="w-full object-cover"
            />
          </UiCustomLink>
        </div>
        <span class="hidden lg:block text-base font-raleway lg:text-lg">{{
            logo.alt
          }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import CustomImage from "~/components/ui/CustomImage.vue";

export default {
  name: "ParagraphThumbnail",
  components: {
    CustomImage,
  },
  props: {
    paragraphData: {
      type: Object,
      required: true,
    },
  },
};
</script>
