<template>
  <section v-if="paragraphData.computedParagraphNewsDomain?.length > 0" class="container mx-auto">
    <div class="flex justify-between">
      <h2 v-if="paragraphData.fieldDomainNews?.fieldTitle" class="font-raleway font-normal text-[2rem] lg:text-h2 m-0">
        {{ paragraphData.fieldDomainNews.fieldTitle }}
      </h2>
      <UiCustomLink
        v-if="backLink"
        :path="backLink"
        class="hidden items-center self-end text-lg font-bold underline lg:flex font-raleway hover:text-orangeCurie"
      >
        {{ $t("actuality.discoverAll") }}
        <nuxt-icon name="arrowRight" filled class="ml-2" />
      </UiCustomLink>
    </div>
    <div class="grid grid-cols-1 grid-rows-1 pt-4 lg:grid-rows-2 lg:grid-cols-12 lg:pt-6 mb-14 lg:mb-20">
      <div
        v-for="(sameNews, index) in paragraphData.computedParagraphNewsDomain"
        :class="{
          'col-span-1 row-span-1 flex flex-col lg:flex-row lg:col-span-8 lg:row-span-2 gap-0 lg:gap-8 lg:mr-10 group relative':
            index === 0,
          'col-span-4 row-span-1  py-4 lg:py-3 relative': index === 1,
          'row-span-2 col-span-4  py-4 lg:py-3 relative': index === 2,
        }"
        :key="sameNews.id"
      >
        <div class="overflow-hidden lg:min-w-[430px] mb-4 rounded underline-animation-border">
          <UiCustomImage
            v-if="index === 0"
            :src="sameNews.fieldImage.entity.uriRawField.list[0].url"
            :alt="sameNews.fieldImage.alt"
            presets="field_header"
            customClass="w-full rounded object-cover col-span-4 group-hover:scale-[1.1] transition ease delay-600"
          />
        </div>
        <div
          :class="{
            'pb-4 lg:pr-10 group-hover:border-b-orangeCurie hover:border-b-1': index === 0,
          }"
        >
          <p class="pb-2.5 font-lora text-regular lg:pt-0 text-orangeCurie">
            {{ sameNews.fieldSubtitle }}
          </p>
          <p class="font-lora text-medium leading-[25px] lg:text-h3Small pb-4 underline-animation-border">
            <UiCustomLink :path="sameNews.pathRawField?.list[0]?.alias">
              {{ sameNews.fieldTitle }}
            </UiCustomLink>
          </p>
          <p
            class="font-raleway text-regularMobile lg:text-regular leading-[25px] pb-4"
            v-if="sameNews.fieldIntroductionNews && index === 0"
          >
            {{ sameNews.fieldIntroductionNews }}
          </p>
          <p class="font-raleway text-small">
            {{ formatDate(sameNews.fieldDate.value) }}
          </p>
        </div>
      </div>
      <UiCustomLink
        v-if="backLink"
        :path="backLink"
        class="flex mt-4 lg:hidden items-center self-end text-regularMobile lg:text-lg font-bold underline font-raleway hover:text-orangeCurie"
      >
        {{ $t("actuality.discoverAll") }}
        <nuxt-icon name="arrowRight" filled class="ml-2" />
      </UiCustomLink>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useBackLink } from "~/composables/backlink";

const { getBackLink } = useBackLink();
const backLink = getBackLink("news_list");

defineProps({
  paragraphData: {
    type: Object,
    required: true,
  },
});
</script>
