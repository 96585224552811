<template>
  <div>
    <div
      v-for="(item, index) in paragraphData.fieldCollapsibleItem"
      :key="item.id"
      class=""
    >
      <div
        @click="toggle(index)"
        class="flex items-center justify-between py-6 border-t border-grisClair cursor-pointer font-raleway text-grisFont"
        :class="{ 'text-orangeCurie': expandedIndex === index }"
      >
        <div class="text-regular font-raleway font-bold w-3/4 lg:w-full">
          {{ item.fieldQuestion }}
        </div>
        <span class="text-lg lg:text-medium font-bold">
          <nuxt-icon v-if="expandedIndex !== index" name="plus" />
          <nuxt-icon v-else name="minus" />
        </span>
      </div>
      <div v-if="expandedIndex === index" class="p-4 text-grisFont">
        <div v-html="item.fieldAnswer" class="wysiwyg text-base font-normal font-raleway lg:text-lg"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ParagraphCollapsible",
  props: {
    paragraphData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expandedIndex: null,
    };
  },
  methods: {
    toggle(index) {
      this.expandedIndex = this.expandedIndex === index ? null : index;
    },
  },
};
</script>
