<template>
<div class="relative col-span-12 lg:col-span-4 border-b border-grisClair lg:border-b-0">
          <div
              class="col-span-12 lg:sticky lg:max-w-[400px] xl:max-w-[420px] 2xl:max-w-[490px] lg:z-0 lg:pr-20 top-[80px]"
          >
            <div class="flex gap-2.5 mb-4">
              <div
                  v-if="JobOffer.entityById.fieldJobContract"
                  class="px-1.5 py-1 font-bold rounded border font-raleway text-orangeCurie border-orangeCurie text-small"
              >
                {{ JobOffer.entityById.fieldJobContract.name }}
              </div>
              <div
                  v-if="JobOffer.entityById.fieldJobLocation"
                  class="flex items-center font-raleway text-small"
              >
                <nuxt-icon
                    name="map-alt"
                    filled
                    class="flex items-center mr-1 text-regular"
                />
                {{ JobOffer.entityById.fieldJobLocation.name }}
              </div>
            </div>
            <h1
                v-if="JobOffer.entityById.fieldTitle"
                class="font-lora text-h3 lg:text-h2Small leading-[45px] lg:leading-[40px] mb-4"
            >
              {{ JobOffer.entityById.fieldTitle }}
            </h1>
            <div
                v-if="JobOffer.entityById.fieldJobSector"
                class="font-raleway text-regularMobile lg:text-regular leading-[25px] mb-4"
            >
              {{ JobOffer.entityById.fieldJobSector.name }}
            </div>
            <ul
                v-if="JobOffer.entityById.fieldJobEntity"
                class="flex overflow-x-scroll gap-2 mb-4 font-raleway lg:text-regular lg:overflow-x-hidden lg:flex-wrap lg:mb-6"
            >
              <li
                  class="border border-grisClair rounded-[100px] px-3 py-1 w-fit text-small"
              >
                {{ JobOffer.entityById.fieldJobEntity.name }}
              </li>
            </ul>
            <UiCustomLink
                v-if="JobOffer.entityById.fieldPdf"
                :path="
                JobOffer.entityById.fieldPdf.entity.uriRawField.list[0].url
              "
                target="_blank"
                customClass="font-raleway text-regular flex leading-[25px] underline font-bold items-center gap-2.5 pb-4 cursor-pointer"
            >
              {{ $t("job.downloadPdf") }}
              <nuxt-icon
                  name="dl"
                  filled
                  class="rounded-lg border-white border-1-1/2"
              />
            </UiCustomLink>
            <div class="flex gap-3 mb-4 align-center lg:mb-6">
              <p class="font-bold font-raleway text-regularMobile lg:text-regular">
                {{ $t("job.shareOffer") }}
              </p>
              <ul class="flex gap-3.5 2xl:gap-6 items-center">
                <!-- Todo Add share link for each social -->
                <li class="cursor-pointer">
                  <nuxt-icon name="Share" filled class="text-xl text-black" @click="copyText(); isCopied = !isCopied;" />
                  <span
                    v-show="isCopied"
                    class="absolute font-bold left-1/2 translate-x-[-50%] border border-grisClair rounded py-1 px-2 bg-white"
                    > {{ $t("copied") }}</span
                  >
                </li>
                <li>
                  <UiCustomLink
                      target="_blank"
                      :path="`https://www.facebook.com/sharer/sharer.php?u=${runtimeConfig.public.frontendHost}${route.href}`"
                  >
                    <nuxt-icon name="Facebook" class="text-xl text-black"/>
                  </UiCustomLink>
                </li>
                <li class="cursor-pointer">
                  <UiCustomLink
                      target="_blank"
                      :path="`https://twitter.com/intent/tweet?url=${runtimeConfig.public.frontendHost}${route.href}&text=${JobOffer.entityById.fieldTitle}`"
                  >
                    <nuxt-icon name="Twitter" class="text-xl text-black"/>
                  </UiCustomLink>
                </li>
                <li class="cursor-pointer">
                  <UiCustomLink
                      target="_blank"
                      :path="`http://www.linkedin.com/shareArticle?mini=true&url=${runtimeConfig.public.frontendHost}${route.href}&title=${JobOffer.entityById.fieldTitle}`"
                  >
                    <nuxt-icon name="LinkedIn" class="text-xl text-black"/>
                  </UiCustomLink>
                </li>
              </ul>
            </div>
            <UiButton
                url="#"
                @click="changeFormStatus($event, formHidden)"
                :buttonLabel="$t('job.returnToJob')"
                size="medium"
                variant="secondary"
                :withIcon="false"
                class="w-full text-center lg:w-full"
                v-if="!formHidden"
            />
            <UiButton
                url="#"
                @click="changeFormStatus($event, formHidden)"
                :buttonLabel="$t('job.apply')"
                size="medium"
                :withIcon="false"
                class="w-full text-center lg:w-full"
                v-if="formHidden"
            />
            <div
                v-if="JobOffer.entityById.fieldTeamUnit.lenght > 0"
                class="px-6 py-4 mt-6 rounded border border-grisClair font-raleway text-regular"
            >
              {{ $t("job.associateUnit") }} :
              <span v-for="team in JobOffer.entityById.fieldTeamUnit">
                {{ team.fieldTitle }}
              </span>
            </div>
          </div>
        </div>
</template>
<script setup lang="ts">
defineProps({
  JobOffer: Object,
  formHidden: Boolean
});

const emit = defineEmits(['changeFormStatus'])

const changeFormStatus = (e, formHidden)=>{
  e.preventDefault();
  emit('changeFormStatus', !formHidden);
  if(window.innerWidth >= 1024){
    var anchor = document.querySelector("#job");
    anchor.scrollIntoView();
  }
}

const runtimeConfig = useRuntimeConfig();
const route = useRoute();
const isCopied = ref(false);

const copyText = () => {
  navigator.clipboard.writeText(runtimeConfig.public.frontendHost + route.href);
};

</script>
