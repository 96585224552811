import type { DefineComponent } from "vue";
import ParagraphTitle from "~/components/common/ParagraphTitle.vue";
import ParagraphWysiwyg from "~/components/common/ParagraphWysiwyg.vue";
import ParagraphCollapsible from "~/components/common/ParagraphCollapsible.vue";
import ParagraphCarouselPersons from "~/components/common/ParagraphCarouselPersons.vue";
import ParagraphKeyFigure from "~/components/common/ParagraphKeyFigure.vue";
import ParagraphQuotation from "~/components/common/ParagraphQuotation.vue";
import ParagraphVideoLegend from "~/components/common/ParagraphVideoLegend.vue";
import ParagraphTagline from "~/components/common/ParagraphTagline.vue";
import ParagraphVisualLegend from "~/components/common/ParagraphVisualLegend.vue";
import ParagraphSource from "~/components/common/ParagraphSource.vue";
import ParagraphGreyBackground from "~/components/common/ParagraphGreyBackground.vue";
import ParagraphColumns from "~/components/common/ParagraphColumns.vue";
import ParagraphPush from "~/components/common/ParagraphPush.vue";
import ParagraphNews from "~/components/common/ParagraphNews.vue";
import ParagraphTextImage from "~/components/common/ParagraphTextImage.vue";
import ParagraphBannerImage from "~/components/common/ParagraphBannerImage.vue";
import ParagraphTestimony from "~/components/common/ParagraphTestimony.vue";
import ParagraphLogo from "~/components/common/ParagraphLogo.vue";
import ParagraphLink from "~/components/common/ParagraphLink.vue";
import ParagraphDonation from "~/components/common/ParagraphDonation.vue";
import ParagraphThumbnail from "~/components/common/ParagraphThumbnail.vue";
import ParagraphCarrouselTestimony from "~/components/common/ParagraphCarrouselTestimony.vue";

type ComponentMap = {
  [key: string]: DefineComponent<{ paragraphData: { type: ObjectConstructor; required: true } }, {}, any>;
};

const componentMap: ComponentMap = {
  ParagraphTitle,
  ParagraphWysiwyg,
  ParagraphCollapsible,
  ParagraphCarouselPersons,
  ParagraphKeyFigure,
  ParagraphQuotation,
  ParagraphVideoLegend,
  ParagraphTagline,
  ParagraphVisualLegend,
  ParagraphSource,
  ParagraphGreyBackground,
  ParagraphColumns,
  ParagraphPush,
  ParagraphNews,
  ParagraphTextImage,
  ParagraphBannerImage,
  ParagraphTestimony,
  ParagraphLogo,
  ParagraphLink,
  ParagraphDonation,
  ParagraphThumbnail,
  ParagraphCarrouselTestimony,
};

export const getComponentByType = (
  typeName: string
): DefineComponent<{ paragraphData: { type: ObjectConstructor; required: true } }, {}, any> | null => {
  return componentMap[typeName] || null;
};
