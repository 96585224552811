<template>
  <div>
    <h2
      v-if="paragraphData.fieldTitle"
      class="mb-6 font-normal font-raleway text-[32px] lg:text-h2 leading-[35px] lg:leading-[44px]"
    >
      {{ paragraphData.fieldTitle }}
    </h2>
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-12 lg:grid-flow-col">
      <div
        v-for="columnsItem in paragraphData.fieldColomnsItem"
        :key="columnsItem.id"
        :class="{
        'col-span-1 lg:col-span-4': paragraphData.fieldColomnsItem.length === 3,
        'col-span-1 lg:col-span-6': paragraphData.fieldColomnsItem.length === 2,
        'col-span-1 lg:col-span-12': paragraphData.fieldColomnsItem.length === 1,
      }"
      >
        <CustomImage v-if="columnsItem.fieldImage?.entity?.uriRawField?.list[0]?.url"
          :src="columnsItem.fieldImage.entity.uriRawField.list[0].url"
          :alt="columnsItem.fieldImage.alt"
          presets="paragraph_columns"
          class="w-full rounded-[4px]"
        />
        <h3 class="text-xl font-normal font-lora"
        :class="columnsItem.fieldImage?.entity?.uriRawField?.list[0]?.url ? 'my-4' : 'mb-4 mt-0'"
        >
          {{ columnsItem.fieldTitle }}
        </h3>
        <Wysiwyg :content="columnsItem.fieldBody" />
      </div>
    </div>
  </div>
</template>
<script>
import CustomImage from "~/components/ui/CustomImage.vue";
import Wysiwyg from "~/components/ui/Wysiwyg.vue";

export default {
  name: "ParagraphColumns",
  components: {
    CustomImage,
    Wysiwyg,
  },
  props: {
    paragraphData: {
      type: Object,
      required: true,
    },
  },
};
</script>
