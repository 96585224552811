<template>
  <div>
    <div v-if="paragraphData" class="video-container">
      <iframe
        width="560"
        height="315"
        :src="`https://www.youtube.com/embed/${paragraphData.fieldVideo}`"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        class="aspect-video"
      ></iframe>
    </div>
    <div v-if="paragraphData.fieldLegend" class="my-4 text-sm font-normal font-raleway wysiwyg" v-html="paragraphData.fieldLegend" />
  </div>
</template>
<script>
export default {
  name: "ParagraphVideoLegend",
  props: {
    paragraphData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.video-container {
  /* Styles pour le conteneur de la vidéo */
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.video-container iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 4px;
}
</style>
