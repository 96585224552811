import { gql } from "graphql-tag";

export const JobOfferQuery = gql`
  query JobOfferQuery($id: ID!, $langcode: Langcode!) {
    entityById(entityType: NODE, id: $id, langcode: $langcode) {
      id
      langcode
      ... on NodeJobOffer {
        translations {
          pathRawField {
            list {
              alias
              langcode
            }
          }
        }
        created
        fieldMetatag {
          value
        }
        fieldDateEnd2 {
          value
        }
        fieldReference
        fieldTitle
        fieldJobContract {
          name
        }
        fieldJobLocation {
          name
        }
        fieldJobSector {
          name
        }
        fieldJobEntity {
          name
        }
        fieldPdf {
          entity {
            uriRawField {
              list {
                url
              }
            }
          }
        }
        fieldTeamUnit {
          ... on NodeUnit {
            fieldTitle
            pathRawField {
              list {
                alias
              }
            }
          }
          ... on NodeTeam {
            fieldTitle
            pathRawField {
              list {
                alias
              }
            }
          }
        }
        fieldIntroduction
        fieldDescription
        fieldProfil
        fieldFooter {
          fieldParagraph {
            ...bannerImageParagraph
            ...donationParagraph
            ...pushParagraph
            ...testimonyParagraph
            ...textImageParagraph
          }
        }
      }
    }
  }

  fragment bannerImageParagraph on ParagraphBannerImage {
    id
    type: entityBundle
    fieldAnchor
    fieldBannerImageItem {
      fieldTitle
      fieldText
      fieldLink {
        title
        uri {
          path
        }
      }
      fieldImage {
        alt
        entity {
          uriRawField {
            list {
              url
            }
          }
        }
      }
      fieldImageMobile {
        alt
        entity {
          uriRawField {
            list {
              url
            }
          }
        }
      }
    }
  }

  fragment donationParagraph on ParagraphDonation {
    id
    type: entityBundle
    fieldAnchor
    fieldTitle
  }

  fragment pushParagraph on ParagraphPush {
    id
    type: entityBundle
    fieldAnchor
    fieldPushItem {
      fieldImage {
        alt
        entity {
          uriRawField {
            list {
              url
            }
          }
        }
      }
      fieldSubtitle
      fieldTitle
      fieldBody
    }
  }

  fragment testimonyParagraph on ParagraphTestimony {
    id
    type: entityBundle
    fieldAnchor
    fieldImage {
      alt
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldSubtitle
    fieldText
    fieldFirstName
    fieldAge
    fieldLink {
      title
      uri {
        path
      }
    }
  }

  fragment textImageParagraph on ParagraphTextImage {
    id
    type: entityBundle
    fieldAnchor
    fieldImage {
      alt
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldPositionImage
    fieldSubtitle
    fieldTitle
    fieldBody
  }
`;
