<template>
  <div :class="getGridClasses">
    <div
      v-for="(imageItem, index) in paragraphData.fieldBannerImageItem"
      :key="index"
      :style="{'--bgImage': 'url(' + imageItem.fieldImage.entity.uriRawField.list[0].url + ')', '--bgImageMobile': 'url(' + imageItem.fieldImageMobile.entity.uriRawField.list[0].url + ')'}"
      class="flex w-full p-4 xl:p-9 bg-center bg-no-repeat bg-cover rounded BgImage"
      :class="getImageHeight"
    >
      <div :class="getFlexClasses">
        <div class="text-white" :class="getWidth">
          <h2
            v-if="paragraphData.fieldBannerImageItem.length === 1"
            class="mb-4 xl:mb-6 text-h3Medium lg:text-h1 font-normal font-lora leading-[33px] lg:leading-[57px]"
          >
            {{ imageItem.fieldTitle }}
          </h2>
          <h2
            v-else
            class="mb-4 lg:mb-[11px] text-[28px] lg:text-[34px] font-normal font-lora leading-10"
          >
            {{ imageItem.fieldTitle }}
          </h2>

          <p class="mb-6 lg:mb-0 text-regularMobile lg:text-regular leading-[25px] font-normal font-raleway">
            {{ imageItem.fieldText }}
          </p>
        </div>
        <div v-if="imageItem.fieldLink" class="inline-flex text-center">
          <UiButton
            :buttonLabel="imageItem.fieldLink.title"
            :url="imageItem.fieldLink.uri.path"
            size="large"
            :withIcon="false"
            class="w-full text-center lg:w-auto"
            :class="paragraphData.fieldBannerImageItem.length > 1 ? 'mt-[11px]' : '' "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ParagraphBannerImage",
  props: {
    paragraphData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getGridClasses() {
      const numberOfItems = this.paragraphData.fieldBannerImageItem.length;
      let columns;
      switch (numberOfItems) {
        case 1:
          columns = "lg:grid-cols-1";
          break;
        case 2:
          columns = "lg:grid-cols-2";
          break;
        default:
          columns = "lg:grid-cols-1";
          break;
      }
      return `grid grid-cols-1 ${columns} gap-y-4 lg:gap-x-4`;
    },
    getImageHeight() {
      return this.paragraphData.fieldBannerImageItem.length === 1
        ? "min-h-[565px] lg:min-h-[490px]"
        : "min-h-[565px] lg:min-h-[350px]";
    },
    getFlexClasses() {
      const numberOfItems = this.paragraphData.fieldBannerImageItem.length;
      let display;
      switch (numberOfItems) {
        case 1:
          display =
            "flex-col justify-end lg:flex-row lg:items-end lg:justify-between";
          break;
        case 2:
          display = "flex-col justify-end";
          break;
        default:
          display = "flex-row items-end justify-between";
          break;
      }
      return `flex ${display} w-full`;
    },
    getWidth() {
      return this.paragraphData.fieldBannerImageItem.length === 1
        ? "w-full lg:w-[70%]"
        : "w-full";
    },
  },
};
</script>
<style>
.BgImage {
  background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.2)), var(--bgImageMobile);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media(min-width: 768px){
  .BgImage {
    background-repeat: no-repeat;
    background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.2)), var(--bgImage);
    background-size: cover;
  }
}

.theme-dark {
  .BgImage {
    background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), var(--bgImageMobile);
  }

  @media(min-width: 768px){
    .BgImage {
      background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), var(--bgImage);
    }
  }
}
</style>