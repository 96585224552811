<template>
  <div class="p-4 lg:p-6 bg-grisBackground">
    <h3 class="flex items-center mb-4 text-xl font-normal font-lora">
      <nuxt-icon name="documents" class="mr-2" />{{  $t("source") }}
    </h3>
    <!-- TODO : Add a wysiwyg component -->
    <div class="wysiwyg font-raleway text-regular" v-html="paragraphData.fieldBody" />
  </div>
</template>
<script>
export default {
  name: "ParagraphSource",
  props: {
    paragraphData: {
      type: Object,
      required: true,
    },
  },
};
</script>
