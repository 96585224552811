<template>
  <div class="flex flex-col items-center w-full mx-auto lg:w-4/5">
    <div v-if="paragraphData.fieldSubtitle" class="text-regular font-medium font-lora text-orangeCurie">
      {{ paragraphData.fieldSubtitle }}
    </div>
    <h2 v-if="paragraphData.fieldTitle" class="font-raleway font-normal leading-[35px] lg:leading-[52px] text-[2rem] lg:text-h2 text-center mt-2.5 mb-6">
      {{ paragraphData.fieldTitle }}
    </h2>
    <div v-if="paragraphData.fieldBody"
      v-html="paragraphData.fieldBody"
      class="wysiwyg text-regular leading-[25px] font-normal text-center font-raleway pb-6 lg:pb-[42px]"
    />
    <ul
        class="lg:px-11 w-full flex flex-wrap content-center  lg:justify-center gap-4 items-center justify-center">
      <li
        v-for="(logo, index) in paragraphData.fieldImages"
        :key="index"
        class="w-[95px] border border-grisClair rounded"
      >
        <CustomImage
          :src="logo.entity.uriRawField.list[0].url"
          presets="paragraph_thumbnail"
          class="w-full object-cover"
        />
      </li>
    </ul>
    <Button v-if="paragraphData.fieldLink"
      :buttonLabel="paragraphData.fieldLink.title"
      :url="paragraphData.fieldLink.uri.path"
      size="large"
      :withIcon="false"
      class="w-full lg:w-auto mt-6 lg:mt-10 text-center"
    />
  </div>
</template>
<script>
import CustomImage from "~/components/ui/CustomImage.vue";
import Button from "~/components/ui/Button.vue";
export default {
  name: "ParagraphLogo",
  components: {
    CustomImage,
    Button,
  },
  props: {
    paragraphData: {
      type: Object,
      required: true,
    },
  },
};
</script>
