<template>
  <div
    v-if="paragraphData && hasNonNullPersons()"
    class="grid grid-cols-2 gap-4 lg:grid-cols-10"
    :class="attrs.sectionType === 'full' ? 'lg:grid-cols-10' : 'lg:grid-cols-12'"
  >
    <div
      v-for="(person, index) in paragraphData.fieldPersons.filter(
              (person) => person != null
            )"
      :key="index"
      :class="attrs.sectionType === 'full' ? 'col-span-1 lg:col-span-2' : 'col-span-1 lg:col-span-4'"
      class="p-2 lg:p-4 border border-grisClair rounded"
    >
      <UiCustomLink :path="person.pathRawField?.list[0]?.alias" class="block">
        <UiCustomImage
          v-if="person.fieldImage"
          :src="person.fieldImage.entity.uriRawField.list[0].url"
          :alt="person.fieldImage.alt"
          presets="paragraph_carousel_persons"
          customClass="w-full rounded"
        />
        <NuxtImg
            v-else
            class="w-full rounded"
            src="/image/default.png"
            presets="paragraph_carousel_persons"
            alt="Default"
        />
        <div class="flex flex-col font-normal">
          <div class="mt-4 mb-2 leading-[26px] text-medium lg:text-h3Small font-lora">
            {{ person.fieldFirstName }}
            <span class="uppercase">{{ person.fieldLastName }}</span>
          </div>
          <p
            v-for="(personTitle, index) in person.fieldPersonTitle"
            :key="index"
            class="text-[15px] lg:text-sm font-raleway"
          >
            {{ personTitle.name }}
          </p>
        </div>
      </UiCustomLink>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { ParagraphCarouselPersons } from "~/interfaces/paragraph";

const props = defineProps({
  paragraphData: Object as PropType<ParagraphCarouselPersons>,
});

const attrs = useAttrs();

function hasNonNullPersons() {
  return props.paragraphData?.fieldPersons.some((person) => person !== null);
}
</script>
